.dropdown-menu {
  position: relative;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  min-width: 160px;
  width: max-content;
  border: 1px solid #3d1caf;
  background: white;
  z-index: 9999;
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.15);
  border-radius: 8px;
}
