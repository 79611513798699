.ad-view-modal {
  padding: 24px;
  overflow: auto;
}

.ad-view-modal .modal-body {
  margin: 0;
}

.ad-view-modal .modal-action,
.ad-view-modal .modal-header {
  display: none;
}

.ad-view-modal .body {
  display: grid;
  grid-template-columns: calc(50% - 24px) 49px calc(50% - 24px);
  width: 100%;
  height: 100%;
}

.ad-view-modal .left-side {
  width: 100%;
}

.ad-view-modal .separator {
  width: 1px;
  height: 100%;
  background: #dbd1f4;
  margin: 0 24px;
}

.ad-view-modal .content {
  color: #000;
  font-size: 12px;
  margin-top: 11px;
  font-weight: 400;
}

.ad-view-modal .description {
  padding: 8px 8px 10px;
  overflow: auto;
}

.ad-view-modal .asset video,
.ad-view-modal .asset img {
  max-width: 100%;
  height: auto;
}

.ad-view-modal .right-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 13px;
}

.ad-view-modal .provider-og {
  background: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 10px;
  text-decoration: none;
  gap: 5px;
  font-size: 10px;
  margin-top: -3px;
}

.ad-view-modal .provider-og .og-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.ad-view-modal .provider-og .og-content .caption {
  text-transform: uppercase;
  color: #8e929c;
}

.ad-view-modal .provider-og .og-content .title {
  color: black;
  font-size: 12px;
}

.ad-view-modal .provider-og .og-content .subtitle {
  color: #8e929c;
}

.ad-view-modal .provider-og .og-action button {
  background: #d2d5db;
  border: 0;
  padding: 7px 10px;
  border-radius: 5px;
  vertical-align: text-bottom;
  font-size: 12px;
  cursor: pointer;
}

.ad-view-modal .right-side .library-select {
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
}

.ad-view-modal .right-side .library-select .selectbox-container {
  padding: 0 11px;
  flex: 1;
}

.ad-view-modal .info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 27px;
}

.ad-view-modal .ad-info .logo {
  width: 32px;
  height: 32px;
}

.ad-view-modal .info .info-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 32px;
}

.ad-view-modal .info .info-piece {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ad-view-modal .info .info-piece .value {
  color: var(--primary-color);
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.ad-view-modal .tags-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ad-view-modal .tags-container .tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.ad-view-modal .tags-container .tag-input input {
  width: 100%;
  margin-top: 14px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
}

.ad-view-modal .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 46px;
}

.ad-view-modal .actions a,
.ad-view-modal .actions button {
  width: 100%;
}

.ad-view-modal .modal-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}

.ad-view-modal .modal-close-icon img {
  width: 13.5px;
  height: 13.5px;
}

@media screen and (max-width: 768px) {
  .ad-view-modal .body {
    grid-template-columns: 100%;
    grid-auto-rows: auto 0 auto;
  }

  .ad-view-modal .right-side {
    margin-top: 24px;
  }

  .ad-view-modal .modal-footer {
    flex-direction: column-reverse;
  }

  .ad-view-modal .modal-footer .navigation-arrows {
    width: 100%;
    justify-content: space-between;
  }
  .ad-view-modal .modal-footer .footer-actions {
    width: 100%;
  }
  .ad-view-modal .modal-footer .footer-actions button,
  .ad-view-modal .modal-footer .footer-actions a {
    width: 100%;
  }
}
