.icon-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 150ms ease;
}

.icon-btn-filled {
  background: #3d1caf26;
}

.icon-btn-bordered {
  border: 1px solid #3d1caf33;
}

.icon-btn:hover {
  background: #3d1caf26;
}
