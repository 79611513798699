.ads-filter-bar {
  min-height: 56px;
  background: #f2edfc;
  box-shadow: 0px 4px 10px rgb(61 28 175 / 15%);
  padding: 10px 0;
}

.filters-list {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr repeat(6, 1fr);
}

.library-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-library-option {
  padding: 10px 14px;
  gap: 10px;
}

.filters-list .selectbox-default .selectbox-options {
  min-width: 281px;
}

@media screen and (max-width: 1200px) {
  .filters-list {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .filters-list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .ads-filter-bar {
    padding: 20px 0;
  }

  .filters-list {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .ads-filter-bar {
    position: fixed;
    width: 100%;
    z-index: 100;
  }
}
