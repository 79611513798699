.input-container {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}
.input-default {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f1ff;
  border: 1px solid rgba(61, 28, 175, 0.2);
  border-radius: 8px;
  padding: 4px 12px;
  max-height: 100%;
  font-weight: 500;
  outline: none;
  font-size: 14px;
}
.input-default::placeholder {
  color: #3d1cafb2;
  font-weight: 500;
}
.input-light {
  border-radius: 4px !important;
}
.input-light input {
  font-weight: 500 !important;
}
.input-light input::placeholder {
  color: rgba(61, 28, 175, 0.4) !important;
  font-size: 12px !important;
}
.input-default input {
  outline: none;
  border: none;
  height: 100%;
  background-color: transparent;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  flex: 1 1;
  max-width: 100%;
  line-height: 17px;
}
.input-default img {
  cursor: pointer;
}
.input-default input::placeholder {
  color: #3d1cafb2;
  font-weight: 500;
}
.input-container .validation-error {
  color: #ff4d4d;
  padding: 0 6px;
  position: relative;
  top: 5px;
}
.input-clear {
  background-color: #fff;
}
.input-square {
  border: 1px solid rgba(61, 28, 175, 0.2);
  border-radius: 4px;
}
.input-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
}
