.text-area-default {
  background: #f6f1ff;
  border: 1px solid rgba(61, 28, 175, 0.2);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 8px;
  outline: none;
  font-weight: 500;
  color: var(--primary-color);
  line-height: 20px;
}
.text-area-default::placeholder {
  color: rgba(61, 28, 175, 0.4);
  font-size: 12px;
}

@media screen and (max-width: 576px) {
  .text-area-default {
    font-size: 16px;
  }
}
