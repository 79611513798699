.collaboration-control-menu {
  width: 380px;
  max-width: 90vw;
}
.collaboration-control-menu .copy-link-section {
  border-bottom: 1px solid #3d1caf26;
}

.collaboration-control-menu .collaborators-section {
  padding: 12px 20px 20px;
}

.collaboration-control-menu .collaborator .collaborator-name,
.collaboration-control-menu .collaborator .collaborator-email {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
