button {
  cursor: pointer;
}

.btn-default {
  background-color: var(--primary-color);
  color: #fff;
  font-weight: 700;
  padding: 8px;
  border-radius: 8px;
  border: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  cursor: pointer;
  transition: all 0.5s ease;
  font-size: 14px;
}

.btn-default:hover {
  opacity: 0.85;
}

.btn-delete {
  background-color: var(--danger-color) !important;
}

.btn-green {
  background-color: #48c86a;
}

.btn-green:hover {
  background-color: #5fe482 !important;
}

.btn-danger {
  background-color: #be2814;
}

.btn-danger-outlined {
  color: #be2814;
  border: 1px solid #be2814;
}

.btn-danger-outlined {
  background-color: white;
  border: 2px solid #be2814;
}

.btn-white {
  color: var(--primary-color);
  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(61, 28, 175, 0.07);
  border: 1px solid rgba(61, 28, 175, 0.2);
}

.btn-clear {
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.btn-gradient {
  background: linear-gradient(180deg, #8a46d1 0%, #6036ee 100%);
  border: 1px solid rgba(61, 28, 175, 0.2);
  box-shadow: 0px 2px 4px rgba(61, 28, 175, 0.1);
}

.btn-clear-hovered {
  padding: 5px 12px !important;
}

.btn-clear-hovered:hover {
  background: rgba(61, 28, 175, 0.07);
  border-radius: 46px;
  opacity: 1 !important;
}
