:root {
  --checkbox-width: 20px;
  --checkbox-height: 20px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
  padding: 10px;
  flex-direction: row-reverse;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
}

.checkbox-bordered {
  border-color: rgba(61, 28, 175, 0.2);
}

.checkbox-bordered.checked {
  border-color: #3d1caf;
}

.checkbox-no-border.checked {
  border-color: rgba(61, 28, 175, 0.2) !important;
}

.checkbox-label {
  font-weight: 600;
}

.checkbox {
  position: relative;
  width: var(--checkbox-width);
  height: var(--checkbox-height);
  text-align: left;
  margin: 0;
}

.checkbox.checkbox-inline {
  display: inline-block;
}

.checkbox label {
  display: inline;
  line-height: 1.25em;
  vertical-align: top;
  clear: both;
  padding-left: 1px;
  cursor: pointer;
}

.checkbox label:before,
.checkbox label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.checkbox label:before {
  width: var(--checkbox-width);
  height: var(--checkbox-height);
  background: #fff;
  border: 2px solid #3d1caf;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.checkbox input[type="checkbox"] {
  outline: 0;
  visibility: hidden;
  width: 1.25em;
  margin: 0;
  display: block;
  float: left;
  font-size: inherit;
}

.checkbox input[type="checkbox"]:checked + label:before {
  background: #3d1caf;
  border: none;
  border-radius: 3px;
}

.checkbox input[type="checkbox"]:checked + label:after {
  content: url(../../../assets//imgs/CheckboxMark.svg);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox input[type="checkbox"]:disabled + label:before {
  border-color: rgba(0, 0, 0, 0.26);
}

.checkbox input[type="checkbox"]:disabled:checked + label:before {
  background: rgba(0, 0, 0, 0.26);
}

.checkbox *:before,
.checkbox *:after {
  box-sizing: border-box;
}
