.modal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.modal-wrapper .modal {
  background: white;
  border-radius: 12px;
  max-width: 380px;
  width: 100%;
}

.modal-wrapper .modal-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 24px 32px 0;
}

.modal-header .close-icon {
  cursor: pointer;
}

.modal-title {
  font-size: 22px;
}

.modal-wrapper .modal-body {
  width: 100%;
  padding: 0 32px 24px;
  max-height: 60vh;
}

.modal-action {
  display: flex;
  margin-top: 12px;
}

.modal-action .action-btn {
  width: 100%;
  padding: 12px;
}

.modal-body .input-default {
  width: 100%;
}

.modal-footer {
  width: 100%;
  background: #fff;
  padding: 25px 24px 24px;
  box-shadow: 0px -3px 7px rgba(61, 28, 175, 0.05);
  border-top: 1px solid #dbd1f4;
}

.modal-lg {
  max-width: 760px !important;
}

.modal-overlay {
  background: rgba(39, 22, 97, 0.4);
}

@media screen and (max-width: 768px) {
  .modal-wrapper .modal {
    width: 95%;
  }
}
