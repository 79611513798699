.masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -60px; /* gutter size offset */
  width: auto;
}
.masonry-grid-column {
  padding-left: 60px;
  background-clip: padding-box;
}

.masonry-grid-column > div {
  margin-bottom: 60px;
}

@media screen and (max-width: 992px) {
  .masonry-grid {
    margin-left: -30;
  }
  .masonry-grid-column {
    padding-left: 30px;
  }

  .masonry-grid-column > div {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .masonry-grid {
    margin-left: -20px;
  }
  .masonry-grid-column {
    padding-left: 20px;
  }

  .masonry-grid-column > div {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 576px) {
  .masonry-grid {
    margin-left: -15px;
  }
  .masonry-grid-column {
    padding-left: 15px;
  }

  .masonry-grid-column > div {
    margin-bottom: 48px;
  }
}
