.dropdown-container {
  position: relative;
  z-index: auto;
}

.dropdown-button {
  cursor: pointer;
  z-index: 0;
}

.dropdown-menu-container {
  position: absolute;
  right: 0;
  z-index: 200;
  display: none;
}

.dropdown-menu {
  position: relative;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  min-width: 160px;
  width: max-content;
  border: 1px solid #3d1caf;
  background: white;
  z-index: 9999;
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.15);
  border-radius: 8px;
}

.dropdown-item {
  color: var(--primary-color);
  font-size: 14px;
  border-bottom: 1px solid #3d1caf4d;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  cursor: pointer;
  font-weight: 700;
}

.dropdown-item div,
.dropdown-item a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 12px;
  width: 100%;
}

.dropdown-item:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown-item:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dropdown-item:hover {
  background: #f9f9f9;
}

.dropdown-item a {
  text-decoration: none;
  color: var(--primary-color);
}

.dropdown-open {
  display: block;
}

.dropdown-container {
  position: relative;
  z-index: auto;
}

.dropdown-menu-container {
  position: absolute;
  right: 0;
  z-index: 200;
  display: none;
}

.dropdown-open {
  display: block;
}
