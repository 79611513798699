.library-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 300;
}

.library-select {
  width: 320px;
}

@media screen and (min-width: 1200px) {
  .ads-container {
    padding-top: 178px;
  }
}

@media screen and (max-width: 1200px) {
  .ads-container {
    padding-top: 234px;
  }
}

@media screen and (max-width: 768px) {
  .library-top-bar {
    position: relative;
  }

  .library-page .navbar-wrapper {
    position: fixed;
  }

  .library-top-bar .ads-filter-bar {
    position: relative;
    margin-top: 72px;
  }

  .ads-container {
    padding-top: 46px;
  }
}
