.ads-inspiration-list {
  margin-top: 16px;
  padding: 16px 32px;
  background: #f6f1ff;
  border-top: 1px solid rgba(61, 28, 175, 0.3);
  border-radius: 0 0 8px 8px;
  max-height: 384px;
  overflow: auto;
}

.ads-inspiration-list a {
  padding: 12px 16px;
}

.ads-inspiration-list div {
  margin-right: 0 !important;
}
