.avatar {
  background-color: #dbd1f4;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar img {
  width: 100%;
  height: auto;
  border-radius: 100%;
}

.avatar-sm {
  width: 24px;
  height: 24px;
}
