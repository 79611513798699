.image-card {
  width: 160px;
  height: 185px;
  padding: 38px 14px 20px;
  border: 1px solid rgba(61, 28, 175, 0.2);
  box-shadow: 0px 2px 12px rgba(61, 28, 175, 0.17);
}

.image-card:hover {
  background-color: #f2edff !important;
}
