.card-container {
  position: relative;
  display: flex;
  height: max-content;
  z-index: 10;
}
.card {
  display: flex;
  flex-direction: column;
  padding: 7px 12px 12px;
  background-color: #fff;
  border-radius: 8px;
  height: auto;
  max-height: fit-content;
  will-change: transform;
  transition: all 0.4s ease;
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.15);
  transition: border 0.2s ease;
  border: 2px solid transparent;
  flex: 1 100%;
}
.card:hover {
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.25);
}
.card-marked {
  border-color: #3d1caf;
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.15);
}
.card .clickable-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.card .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 20;
}
.card .header img {
  position: absolute;
  left: calc(50% - 12px);
  cursor: grab;
}
.header-clickable-area {
  height: 100%;
  background-color: transparent;
  flex: 1;
}
.card .asset {
  flex: 1;
  height: max-content;
  margin-top: 6px;
  background: linear-gradient(0deg, #f6f1ff, #f6f1ff);
  border: 1px solid rgba(61, 28, 175, 0.2);
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  margin-bottom: 3px;
}
.card:hover > .asset .upload-icon {
  opacity: 1;
}
.card .asset div {
  height: 100%;
}
.card .asset .asset-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  min-height: 300px;
}

.card .asset .asset-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  min-height: 300px;
}

.card .asset-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  min-height: 300px;
}
.asset-loader p {
  margin-top: 10px;
}
.asset-loader div {
  height: 20px !important;
}
.card .asset .asset-empty p {
  margin-top: 7px;
}
.card .asset .asset-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .asset .asset-img img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
}
.card .asset-video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.card .asset-video video {
  width: 100%;
  z-index: 200;
}
.card .note-text {
  margin-top: 6px;
  width: 100%;
  height: max-content;
  z-index: 10;
}
.card .asset {
  position: relative;
}
.file-input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
}

.asset-loader-progress {
  position: absolute;
  width: calc(100% - 16px);
  background: var(--secondary-color);
  bottom: 8px;
  height: 4px !important;
  border-radius: 31px;
  left: 8px;
  overflow: hidden;
}
.asset-loader-progress .loaded {
  height: 100%;
  border-radius: 31px;
  background-color: var(--primary-color);
  width: 0;
  transition: all 0.5s ease-in-out;
}
.asset .upload-icon {
  height: auto !important;
  z-index: 250;
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0;
  transition: all 0.5s ease;
}
.asset .upload-icon img {
  width: 26px;
}

.card .header img {
  transition: all 0.5s ease;
  border-radius: 5px;
}
.card .header img:hover,
.card .header img:hover {
  background-color: rgb(51, 51, 51, 0.07);
}
.add-between-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 48px;
  margin: auto;
  height: 90%;
  opacity: 0;
  transition: all 0.2s ease;
  position: absolute;
  left: 100%;
  top: 5%;
  bottom: 5%;
}
.add-between-card:hover {
  opacity: 1;
}
.add-between-card img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.add-between-card .bar {
  width: 10px;
  background: rgba(61, 28, 175, 0.15);
  border-radius: 2px;
  height: calc(50% - 21.5px);
}

@media screen and (max-width: 1200px) {
  .add-between-card {
    width: 24px;
  }
  .add-between-card .bar {
    width: 5px;
    height: calc(50% - 14.5px);
  }
  .add-between-card img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 576px) {
  .add-between-card {
    width: 90%;
    height: 48px;
    flex-direction: row;
    top: 100%;
    left: 5%;
    right: 5%;
    bottom: auto;
  }
  .add-between-card .bar {
    width: calc(50% - 21.5px);
    height: 10px;
  }
}
