.new-frame-card {
  min-height: 473px;
  height: 529px;
  padding: 7px 12px 12px;
  background: rgba(246, 241, 255, 0.2);
  border: 1px dashed rgba(61, 28, 175, 0.2);
}

@media screen and (max-width: 576px) {
  .new-frame-card {
    width: 100%;
  }
}
