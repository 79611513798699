.ads-inspiration-setup-modal {
  max-width: 664px !important;
  background: linear-gradient(180deg, #f9f7ff 0%, #dbd1f4 100%) !important;
}

.ads-inspiration-setup-modal .modal-body {
  padding: 51px;
}

.ads-inspiration-setup-modal .form,
.ads-inspiration-setup-modal .footer {
  max-width: 356px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .ads-inspiration-setup-modal .modal-body {
    padding: 46px 20px 40px;
    max-height: 100%;
  }
}
