.dropdown-menu-item {
  color: var(--primary-color);
  font-size: 14px;
  border-bottom: 1px solid #3d1caf4d;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  cursor: pointer;
  font-weight: 700;
}

.dropdown-menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 12px;
  width: 100%;
  user-select: none;
}

.dropdown-menu-item:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown-menu-item:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dropdown-menu-item:hover {
  background: #f9f9f9;
}

.dropdown-menu-item a {
  text-decoration: none;
  color: var(--primary-color);
}
