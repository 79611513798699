.status-dropdown {
  font-size: 11px;
  line-height: 13px;
}

.status-dropdown-menu {
  right: -15% !important;
}

.status-progress {
  border-color: var(--info-color) !important;
  background-color: var(--info-color-light);
}

.status-progress:hover {
  background-color: #d7ecff;
}

.status-progress .circle-sm {
  background-color: var(--info-color);
}

.status-approved {
  border-color: var(--success-color) !important;
  background-color: var(--success-color-light);
}

.status-approved:hover {
  background-color: #c3f5d5;
}

.status-approved .circle-sm {
  background-color: var(--success-color);
}

.status-needs-review {
  border-color: var(--warning-color) !important;
  background-color: var(--warning-color-light);
}

.status-needs-review:hover {
  background-color: #ff990042;
}

.status-needs-review .circle-sm {
  background-color: var(--warning-color);
}

.status-archived {
  border-color: #d3c8f5 !important;
  background-color: #f7f3ff;
}

.status-archived:hover {
  background-color: #e5dbff;
}

.status-archived .circle-sm {
  background-color: #8e78da;
}

.status-dropdown-item {
  border-bottom: 0;
  padding: 0;
}

.status-selection {
  font-size: 11px;
  line-height: 13px;
  width: 166px;
}
