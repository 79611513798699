.get-started-modal {
  max-width: 664px !important;
  background: linear-gradient(180deg, #f9f7ff 0%, #dbd1f4 100%) !important;
}

.get-started-modal .modal-body {
  padding: 46px 56px 80px;
  max-height: 430px;
}

@media screen and (max-width: 768px) {
  .get-started-modal .modal-body {
    padding: 46px 20px 40px;
    max-height: 100%;
  }
}
