.boards-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin: 0 auto;
  padding-top: 16px;
}

.boards-loader svg {
  border-radius: 8px;
}
