.board-list a {
  text-decoration: none;
}
.board-card {
  background-color: #fff;
  padding: 0 16px;
  height: 72px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: auto;
  transition: all 0.25s ease;
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.15);
}
.board-card:hover {
  background-color: #f3ecff;
}
.board-title {
  flex: 1;
  padding: 0 8px;
  height: 100%;
}
.board-title a {
  font-size: 17px;
  line-height: 20.5px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  color: var(--primary-color);
}
.board-description {
  width: 100%;
  height: 100%;
  align-items: flex-start !important;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}
.board-description .board-update-info {
  font-size: 10px;
  font-weight: 700;
  color: rgba(61, 28, 175, 0.4);
  line-height: 12.1px;
  letter-spacing: 0.5px;
}
.board-actions {
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: auto;
}
.board-actions .icon-btn,
.board-actions .icon-btn img {
  z-index: auto;
}

.board-status {
  width: 166px;
}

@media screen and (max-width: 586px) {
  .board-status {
    display: none;
  }
}
