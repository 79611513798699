.Toastify__toast {
  min-height: 40px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  box-shadow: none;
  width: max-content;
  padding: 10px 15px;
  margin-left: auto;
  margin-right: auto;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast-container--top-center {
  top: 14px;
  width: 100%;
  max-width: max-content;
  padding: 0;
}

.toast-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}

.toast-success {
  background: #ccf4e2;
  border-color: #48c86a;
  color: #148e35;
}

.toast-warning {
  background: rgb(255 227 141 / 50%);
  border-color: rgba(255, 193, 7, 1);
  color: rgb(155 118 3);
  font-size: 14px;
  font-weight: 700;
}

.toast-danger {
  background: #f7d9db;
  border-color: #c84848;
  color: #a80b0b;
  font-size: 14px;
  font-weight: 700;
}

@media screen and (max-width: 480px) {
  .Toastify__toast-container--top-center {
    left: 10px;
  }
}
