.navbar-wrapper {
  height: var(--navbar-height);
  max-width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(61, 28, 175, 0.35);
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 210;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-fixed {
  position: fixed;
}

.navbar .brand {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.navbar .logo {
  max-width: 136px;
}

.navbar .logo-mini {
  width: auto;
  height: 34px;
  max-width: 50px;
  margin-left: 10px;
}

.navbar .brand p {
  margin-left: 4px;
  margin-top: 2px;
}

.navbar .link-generator,
.navbar .right-side {
  display: flex;
  height: 40px;
  gap: 10px;
}
.marked-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.marked-container .marked-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 40px;
  height: 40px;
  background: rgba(61, 28, 175, 0.2);
  border: 2px solid #3d1caf;
  border-radius: 8px;
}
.content-updating-indicator {
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 0.5s ease-in;
  color: rgba(61, 28, 175, 0.7);
  font-size: 14px;
}
.content-updating-indicator div {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.content-updating-indicator .updated {
  gap: 12px;
}
.page-link-input .input-default {
  height: 100%;
}

.board-title {
  display: flex;
  align-items: center;
  gap: 18px;
}

.copy-board-link {
  display: none;
}

.navbar .navigation-links {
  display: flex;
  gap: 10px;
}

.navigation-link .active-bar {
  width: 40px;
  height: 5px;
  background: #6538ec;
  border-radius: 8px 8px 0px 0px;
  transform: rotate(-180deg);
  position: fixed;
  top: 0;
}

.navigation-links .navigation-btn {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) -116.25%,
    rgba(147, 74, 204, 0.2) -17.7%,
    rgba(95, 54, 239, 0.2) 100%
  );
  border: 1px solid #d3c8f5;
  box-shadow: 0px 2px 4px rgba(61, 28, 175, 0.1);
  border-radius: 8px;
}

.navigation-links .navigation-btn.active {
  background: linear-gradient(
    180deg,
    #ae62d2 -116.25%,
    #934acc -17.7%,
    #5f36ef 100%
  );
  border: 0;
}

@media screen and (max-width: 576px) {
  .navbar .brand p {
    display: none;
  }
  .content-updating-indicator div {
    margin-right: 5px;
  }

  .navbar .copy-link-button {
    display: none;
  }
  .copy-board-link {
    display: block;
  }
}

@media screen and (max-width: 786px) {
  .link-generator .page-link-input {
    display: none;
  }
  .link-word {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .content-updating-indicator div p {
    display: none;
  }
}
