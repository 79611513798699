.carousel-container {
  position: relative !important;
}

.carousel .control-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 45%;
  background: #fff;
  position: absolute;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  width: 24px;
  height: 24px;
  font-weight: 700;
  user-select: none;
}

.control-btn.next {
  right: 5px;
}

.control-btn.prev {
  left: 5px;
}

.carousel .item-video video {
  max-width: 100%;
}
