.magic-brief-main {
  height: 100%;
  display: flex;
  min-height: calc(100vh - var(--navbar-height));
  margin-top: var(--navbar-height);
}

.board-frames {
  margin-top: 46px;
  margin-bottom: 40px;
}

.frames-list-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;
  margin-top: calc(24px + 56px);
}

.frames-list-view .new-frame-card {
  height: 204px;
  min-height: 204px;
}

.frames-grid-view {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  gap: 48px;
  height: max-content;
}

@media screen and (max-width: 1200px) {
  .frames-grid-view {
    gap: 24px;
  }
}

@media screen and (max-width: 992px) {
  .frames-grid-view {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 786px) {
  .frames-grid-view {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;
  }
}

@media screen and (max-width: 576px) {
  .frames-grid-view {
    grid-template-columns: 1fr;
    gap: 48px;
  }
}
