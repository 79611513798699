.tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f6f1ff;
  border: 1px solid rgba(61, 28, 175, 0.2);
  border-radius: 6px;
  height: 30px;
}

.tag .tag-close-icon {
  cursor: pointer;
  padding-right: 12px;
}

.tag-content {
  padding: 7.5px 10px;
  font-size: 12px;
  font-weight: 600;
}
