.switch-container {
  background: #f6f1ff;
}

.switch-border-prefix {
  border: 1px solid rgba(61, 28, 175, 0.2);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: absolute;
  background: transparent;
  z-index: 0;
}
