.ad-details-card {
  background: white;
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.15);
  border-radius: 12px;
}

.ad-details-card .left-side,
.ad-details-card .right-side {
  padding: 24px;
}

.ad-details-card .left-side {
  padding-right: 0;
}

.ad-details-card .right-side {
  padding-left: 0;
}

.ad-details-card .modal-body {
  margin: 0;
}

.ad-details-card .modal-action,
.ad-details-card .modal-header {
  display: none;
}

.ad-details-card .body {
  display: grid;
  grid-template-columns: calc(50% - 24px) 49px calc(50% - 24px);
  width: 100%;
  height: 100%;
}

.ad-details-card .left-side {
  width: 100%;
}

.ad-details-card .separator {
  width: 1px;
  height: 100%;
  background: #dbd1f4;
  margin: 0 24px;
}

.ad-details-card .content {
  color: #000;
  font-size: 12px;
  margin-top: 11px;
  font-weight: 400;
}

.ad-details-card .description {
  padding: 8px 8px 10px;
  overflow: auto;
}

.ad-details-card .asset video,
.ad-details-card .asset img {
  max-width: 100%;
  height: auto;
}

.ad-details-card .right-side {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ad-details-card .provider-og {
  background: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 10px;
  text-decoration: none;
  gap: 5px;
  font-size: 10px;
  margin-top: -3px;
}

.ad-details-card .provider-og .og-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.ad-details-card .provider-og .og-content .caption {
  text-transform: uppercase;
  color: #8e929c;
}

.ad-details-card .provider-og .og-content .title {
  color: black;
  font-size: 12px;
}

.ad-details-card .provider-og .og-content .subtitle {
  color: #8e929c;
}

.ad-details-card .provider-og .og-action button {
  background: #d2d5db;
  border: 0;
  padding: 7px 10px;
  border-radius: 5px;
  vertical-align: text-bottom;
  font-size: 12px;
  cursor: pointer;
}

.ad-details-card .right-side .library-select {
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
}

.ad-details-card .right-side .library-select .selectbox-container {
  padding: 0 11px;
  flex: 1;
}

.ad-details-card .info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 27px;
}

.ad-details-card .ad-info .logo {
  width: 32px;
  height: 32px;
}

.ad-details-card .info .info-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 32px;
}

.ad-details-card .info .info-piece {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ad-details-card .info .info-piece .value {
  color: var(--primary-color);
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.ad-details-card .tags-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ad-details-card .tags-container .tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.ad-details-card .tags-container .tag-input input {
  width: 100%;
  margin-top: 14px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
}

.ad-details-card .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 46px;
}

.ad-details-card .actions a,
.ad-details-card .actions button {
  width: 100%;
}

.ad-details-card .modal-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}

.ad-details-card .modal-close-icon img {
  width: 13.5px;
  height: 13.5px;
}

.ad-details-card .card-footer {
  width: 100%;
  background: #fff;
  padding: 25px 24px 24px;
  box-shadow: 0px -3px 7px rgb(61 28 175 / 5%);
  border-top: 1px solid #dbd1f4;
  border-radius: 0 0 16px 16px;
}

.ad-details-card .card-footer .footer-actions {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .ad-details-card .right-side,
  .ad-details-card .left-side {
    padding: 24px;
  }

  .ad-details-card .right-side {
    padding-top: 0;
  }

  .ad-details-card .body {
    grid-template-columns: 100%;
    grid-auto-rows: auto 0 auto;
  }

  .ad-details-card .right-side {
    margin-top: 24px;
  }

  .ad-details-card .modal-footer {
    flex-direction: column-reverse;
  }

  .ad-details-card .modal-footer .navigation-arrows {
    width: 100%;
    justify-content: space-between;
  }
  .ad-details-card .modal-footer .footer-actions {
    width: 100%;
  }
  .ad-details-card .modal-footer .footer-actions button,
  .ad-details-card .modal-footer .footer-actions a {
    width: 100%;
  }
}

.ad-container {
  padding: 12px 14px;
}

.ad-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ad-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.ad-provider {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.ad-provider h3 {
  font-size: 12px;
  font-weight: 600;
  color: var(--primary-color);
}

.ad-provider p {
  font-size: 10px;
  font-weight: 500;
  color: rgba(61, 28, 175, 0.7);
}

.ad-info .logo img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.ad-container .asset {
  margin-top: 12px;
}

.ad-container .carousel-container {
  position: absolute;
  width: 100%;
}

.ad-container .asset-img .carousel .item-image img {
  height: 100%;
}
