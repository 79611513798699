.tabs-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #f6f1ff;
  border-radius: 8px;
}

.tabs-list .border-prefix {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid rgba(61, 28, 175, 0.2);
  border-radius: 8px;
}

.tab-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 8px;
  color: rgba(61, 28, 175, 0.7);
  font-weight: 500;
  cursor: pointer;
  z-index: 10;
  text-align: center;
}

.tab-active {
  background-color: #fff;
  color: #3d1caf;
  border-color: #3d1caf;
  font-weight: 600;
}

.tab-body {
  margin-top: 14px;
}
