.selectbox-wrapper {
  background-color: #f6f1ff;
  border-radius: 8px;
  border: 1px solid #3d1caf33;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  position: relative;
  cursor: pointer;
  padding: 0;
}

.selectbox-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  padding: 6px 8px 6px 12px;
  z-index: 100;
  height: 100%;
}

.selectbox-body.resetable-body {
  background: rgba(61, 28, 175, 0.1);
  border-radius: 7px;
}

.selectbox-wrapper .placeholder {
  color: #3d1cafb2;
  font-size: 14px;
}

.selectbox-wrapper p {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
}

.selectbox-content {
  display: none;
}

.selectbox-options-container {
  max-height: calc((40px * 3) + 15px);
  overflow: auto;
}

.selectbox-options {
  position: absolute;
  top: calc(100% + 8px);
  background: #fff;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 100;
  overflow-y: auto;
  border: 1px solid #3d1caf;
  box-shadow: 0px 2px 2px rgba(61, 28, 175, 0.07);
  border-radius: 8px;
  max-height: max-content;
}

.selectbox-options .selectbox-option {
  padding: 12px 16px;
  border-bottom: 1px solid #3d1caf4d;
  font-weight: 500;
  font-size: 14px;
}

.selectbox-options .option:hover {
  background-color: #f9f9f9;
}

.selectbox-options.hidden {
  display: none;
}

.option-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.selectbox-options-head,
.selectbox-options-actions {
  display: flex;
  flex-direction: column;
}

.selectbox-head-item,
.selectbox-action-item {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

/* Search */
.selectbox-search {
  padding: 12px;
  border-bottom: 1px solid rgba(61, 28, 175, 0.23);
}

.selectbox-search .input-default {
  background: #f2edfc;
  border: 1px solid rgba(61, 28, 175, 0.2);
  border-radius: 8px;
  height: 40px;
}
