.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.form-body {
  background: #fff;
  border-radius: 12px 12px 0 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.form-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}
.form-body .input-group {
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 100%;
}
.form-body .input-group .input-default {
  height: 40px;
}
.form-body .confirmation-text {
  color: #9e8fce;
  text-align: center;
  width: 101%;
  line-height: 20px;
}
.confirmation-text a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
}
.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.action-buttons button {
  width: 100%;
  height: 40px;
}
.action-buttons .or-text {
  color: #92929d;
  font-weight: 700;
}
.form-footer {
  background: #eeecf8;
  border-radius: 0 0 12px 12px;
  padding: 17px;
}
.form-footer {
  text-align: center;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
}
.form-footer a {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 700;
}
