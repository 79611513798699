.dashboard {
  margin-bottom: 48px;
}
.dashboard-content {
  margin-top: calc(var(--navbar-height) + 32px);
}
.dashboard-content .title {
  padding: 0 24px;
  font-size: 18px;
}

.projects-list {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-bottom: 48px;
}
