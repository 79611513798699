.extension-installed-banner {
  background: #fdfdff;
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.15);
  height: 235px;
}

.extension-installed-banner .left-side {
  padding: 42px;
}

.extension-installed-banner .left-side .title {
  font-size: 27px;
  line-height: 32px;
}

.extension-installed-banner .step-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  padding: 4px 8px;
  background: #efe9fe;
  border-radius: 21px;
  width: 95px;
  height: 35px;
  text-align: center;
  color: var(--primary-color);
  margin-right: 28px;
}

.extension-installed-banner .top-ads-button {
  width: 257px;
  background: linear-gradient(
    180deg,
    #ae62d2 -116.25%,
    #934acc -17.7%,
    #5f36ef 100%
  );
  box-shadow: 0px 2px 4px rgba(61, 28, 175, 0.1);
  border-radius: 8px;
  padding: 14px 32px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  line-height: 22px;
  color: #fff !important;
}

.extension-installed-banner .step2-arrow {
  position: relative;
  left: 73px;
  top: 13px;
}

.extension-installed-banner .right-side {
  overflow: hidden;
}

.extension-installed-banner .right-side .fb-ad-card {
  position: relative;
  top: 20px;
}

.extension-installed-banner .right-side .arrow-bottom-left {
  position: relative;
  top: 20px;
  left: 4px;
}

.extension-installed-banner .step-3 {
  gap: 13px;
}

.extension-installed-banner .step-3 .content {
  margin-left: 20px;
  line-height: 21px;
}

.extension-installed-banner .close-icon {
  top: 30px;
  right: 30px;
  width: 13px;
  height: 13px;
}

@media (max-width: 1150px) {
  .extension-installed-banner {
    flex-direction: column;
    height: fit-content;
  }
  .extension-installed-banner .fb-ad-card {
    width: 260px;
  }

  .extension-installed-banner .left-side,
  .extension-installed-banner .right-side {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
