.horizontal-frame-card {
  min-height: 204px;
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.15);
  z-index: 10;
}

.horizontal-frame-card.marked {
  border-color: var(--primary-color) !important;
}

.horizontal-frame-card .asset-container {
  width: 150px;
  max-width: auto;
}

.horizontal-frame-card .asset-container .asset-upload-icon {
  top: 8px;
  right: 8px;
}

.horizontal-frame-card:hover > .asset-container .asset-upload-icon {
  opacity: 1;
}
