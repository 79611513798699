.video-page-result-content {
  margin-top: calc(var(--navbar-height) + 42px);
}

.dropzone-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 72px);
  gap: 24px;
  padding: 20px;
}
.video-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 522px;
  height: 219px;
  background: #f6f1ff;
  border: 1px solid rgba(61, 28, 175, 0.5);
  box-shadow: 0px 4px 10px rgba(61, 28, 175, 0.15);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}
.upload-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.upload-thumb {
  background-color: rgba(61, 28, 175, 0.2);
  width: 90%;
  height: 70%;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.upload-thumb p {
  color: white;
  background-color: var(--primary-color);
  font-size: 14px;
  text-align: center;
  padding: 7px 0;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.video-dropzone p {
  margin-top: 16px;
}
.video-input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.frame-caputre-area {
  display: flex;
  width: 100%;
  max-width: 522px;
  gap: 26px;
  align-items: center;
}
.frames-caputre-number input {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  outline: none;
}
.capture-tip {
  font-size: 11px;
}
.button-up-down {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border: 1px solid rgba(61, 28, 175, 0.5);
  box-sizing: border-box;
  border-radius: 8px;
  width: 100px;
  height: 50px;
}
.up-down {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
}

.up-down-text {
  display: flex;
  border-right: 1px solid rgba(61, 28, 175, 0.5);
  height: 100%;
  align-items: center;
}
.up-down-text input {
  text-align: center;
}
.centers {
  display: flex;
  align-items: center;
}
.process-video-btn {
  background-color: var(--primary-color);
  position: relative;
  color: white;
  background: #3d1caf;
  border-radius: 8px;
  width: 522px;
  height: 54px;
  border: 0;
  cursor: pointer;
  max-width: 100%;
}
.video-uploading {
  background: #9e88d5;
}

.video-progress {
  height: 100%;
  position: absolute;
  background: #3d1caf;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}
