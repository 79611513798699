@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

:root {
  --primary-color: #3d1caf;
  --primary-color-70: rgba(61, 28, 175, 0.7);
  --secondary-color: #dbd1f4;
  --secondary-color-48: rgba(219, 209, 244, 0.48);
  --danger-color: #ec3118;
  --info-color: #7dc1ff;
  --info-color-light: #e4f9ff;
  --success-color: #91dea6;
  --success-color-light: #e1f8e9;
  --warning-color: #f3bd6b;
  --warning-color-light: #ff99002b;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: 0.1px;
  box-sizing: border-box;
  background-color: var(--secondary-color);
}

#root {
  height: 100%;
}

body * {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  /* z-index: 5; */
}

/* Utility Classes */
.hidden {
  display: none !important;
}

.d-none {
  display: none;
}

.d-block {
  display: block !important;
}

.flex,
.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.decoration-none {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.fully-centered {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

/* Borders */
.border-0 {
  border: 0 !important;
}

.border-1 {
  border: 1px solid transparent !important;
}

.border-2 {
  border: 2px solid transparent !important;
}

/* Border Color */
.border-color-20 {
  border-color: rgba(61, 28, 175, 0.2) !important;
}

.border-color-25 {
  border-color: rgba(61, 28, 175, 0.25) !important;
}

/* Shadows */
.shadow-0 {
  box-shadow: none !important;
}

/* Hover */
.hover-0:hover {
  background-color: transparent !important;
}

/* Colors */
.text-white {
  color: #fff !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-primary-70 {
  color: var(--primary-color-70) !important;
}

.text-danger {
  color: var(--danger-color) !important;
}

/* Typography */
.text-base {
  font-size: 12px !important;
}

.text-sm {
  font-size: 11px !important;
}

.text-md {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.text-lg {
  font-size: 16px;
}

.text-xl {
  font-size: 18px;
}

.text-2xl {
  font-size: 22px;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400 !important;
}

.font-bold {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-extrabold {
  font-weight: 700 !important;
}

.uppercase {
  text-transform: uppercase;
}

/* Line Height */
.line-height-18 {
  line-height: 18px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-23 {
  line-height: 23px;
}

.line-height-25 {
  line-height: 25px;
}

.label {
  font-size: 10px;
  font-weight: 700;
  color: rgba(61, 28, 175, 0.4);
}

/* Backgrounds */
.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-secondary-48 {
  background-color: var(--secondary-color-48);
}

.bg-light-pink {
  background-color: #f6f1ff;
}

.bg-white {
  background-color: #fff !important;
}

/* Width */
.w-16 {
  width: 16px !important;
}

.w-24 {
  width: 24px !important;
}

.w-40 {
  width: 40px !important;
}

.w-48 {
  width: 48px !important;
}

.w-265 {
  width: 265px;
}

.w-500 {
  width: 500px;
}

/* Min Width */
.min-w-40 {
  min-width: 40px !important;
}

/* Height */
.h-auto {
  height: auto !important;
}

.h-16 {
  height: 16px !important;
}

.h-24 {
  height: 24px !important;
}

.h-32 {
  height: 32px !important;
}

.h-40 {
  height: 40px;
}

.h-48 {
  height: 48px;
}

.h-56 {
  height: 56px;
}

/* Radius */
.rounded-0 {
  border-radius: 0 !important;
}

.rounded-4 {
  border-radius: 4px !important;
}

.rounded-6 {
  border-radius: 6px !important;
}

.rounded-8 {
  border-radius: 8px !important;
}

.rounded-12 {
  border-radius: 12px;
}

.rounded-14 {
  border-radius: 14px;
}

.rounded-16 {
  border-radius: 16px !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

/* Gaps */
.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px !important;
}

.gap-16 {
  gap: 16px;
}

.gap-14 {
  gap: 14px;
}

.gap-18 {
  gap: 18px;
}

.gap-20 {
  gap: 20px;
}

.gap-22 {
  gap: 22px;
}

.gap-24 {
  gap: 24px;
}

/* Img */
.img-sm {
  width: 24px;
  height: 24px;
}
.img-md {
  width: 40px;
  height: 40px;
}
.img-flip {
  transform: rotate(180deg);
}

/* Padding */
.p-0 {
  padding: 0 !important;
}

.p-4 {
  padding: 4px !important;
}

.p-5 {
  padding: 5px;
}

.p-8 {
  padding: 8px;
}

.p-12 {
  padding: 12px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-34 {
  padding: 34px !important;
}

/* Padding right */
.pr-0 {
  padding-right: 0 !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

/* Padding bottom */
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

/* Padding Left */
.pl-0 {
  padding-left: 0 !important;
}

/* Padding Y */
.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

/* Padding X */
.px-22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

/* Width */
.w-full {
  width: 100%;
}

/* Max Width */
.max-width-none {
  max-width: none !important;
}

/* Height */
.h-full {
  height: 100%;
}

/* Layouts */
.container {
  max-width: 1300px;
  margin: 0 auto;
}

/* Margins */
.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-42 {
  margin-top: 42px;
}

.mt-46 {
  margin-top: 46px;
}

.mt-48 {
  margin-top: 48px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.hide {
  display: none !important;
}

/* Z-index */
.z--1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-100 {
  z-index: 100 !important;
}

.z-200 {
  z-index: 200 !important;
}

.z-300 {
  z-index: 300 !important;
}

.z-400 {
  z-index: 400 !important;
}

.z-500 {
  z-index: 500 !important;
}

.z-600 {
  z-index: 600 !important;
}

.z-700 {
  z-index: 700 !important;
}

.z-800 {
  z-index: 800 !important;
}

.z-900 {
  z-index: 900 !important;
}

.z-1000 {
  z-index: 1000 !important;
}

/* Opacity */
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

/* Transform */
.rotate-180 {
  transform: rotate(180deg);
}

/* Transition */
.transition-0\.5 {
  transition: all 0.5s ease;
}

/* Positions */
.relative {
  position: relative;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed;
}

/* Overflow */
.overflow-auto {
  overflow: auto;
}

.overflow-x-clip {
  overflow-x: clip !important;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer !important;
}

/* Text Align */
.text-center {
  text-align: center;
}

/* Circles */
.circle-sm {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  background: transparent;
  z-index: 200;
}

.dropdown-overlay {
  z-index: 5;
}

.img-filled {
  background: #3d1caf;
  border-radius: 3px;
  padding: 4px;
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1300px) {
  .container {
    padding: 0 24px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 14px;
  }

  .hide-sm {
    display: none;
  }

  /* Paddings */
  .md-p-0 {
    padding: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .hide-md {
    display: none;
  }
}
