.extension-banner {
  width: 100%;
  max-height: 235px;
  background: #faf8ff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  column-gap: 130px;
  row-gap: 48px;
  filter: drop-shadow(0px 3.46354px 8.65886px rgba(61, 28, 175, 0.15));
}

.extension-banner .left-side {
  padding: 42px 0 42px 42px;
  font-size: 27px;
  font-weight: 700;
  line-height: 32.68px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.extension-banner .chrome-icon {
  width: 68px;
  height: 68px;
  margin-top: 3px;
}

.extension-banner .extension-link-btn {
  width: 257px;
  background: linear-gradient(
    180deg,
    #ae62d2 -116.25%,
    #934acc -17.7%,
    #5f36ef 100%
  );
  box-shadow: 0px 2px 4px rgba(61, 28, 175, 0.1);
  border-radius: 8px;
  padding: 14px 32px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  line-height: 22px;
  color: #fff !important;
}

.extension-banner .right-side {
  display: flex;
  position: relative;
  padding-right: 14px;
  padding: 27px 68px 0 0;
  filter: drop-shadow(0px 3.46354px 8.65886px rgba(61, 28, 175, 0.15));
}

.extension-banner .facebook-library-img {
  width: 502px;
  height: fit-content;
}

.extension-banner .facebook-ad-card {
  max-width: 310px;
  height: auto;
  position: absolute;
  top: 40px;
  left: -29%;
}

@media (max-width: 768px) {
  .extension-banner {
    flex-direction: column-reverse;
  }

  .extension-banner .right-side {
    justify-content: center;
    padding-bottom: 0;
  }

  .extension-banner .facebook-library-img {
    width: 100%;
  }

  .extension-banner .facebook-ad-card {
    max-width: 45%;
    top: 16%;
    left: -2.5%;
  }
}

@media (max-width: 586px) {
  .extension-banner .title {
    font-size: 25px;
  }

  .extension-banner .arrow-left {
    display: none;
  }
}
