.login-page {
  padding: 12px;
  max-width: 360px;
  margin: auto;
  height: calc(100vh - 24px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-sizing: content-box;
}
.login-page .form-container {
  width: 100%;
  box-shadow: 0px 4px 10px 0px #3d1caf26;
}
.privacy-terms {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-top: 27px;
}
.privacy-terms a {
  text-decoration: none;
  color: var(--primary-color);
}

@media screen and (max-width: 578px) {
  .login-page {
    margin-top: 0;
  }
}
