.frames-loader {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 48px;
  width: 100%;
  height: max-content;
  margin-top: 46px;
  margin-bottom: 40px;
  z-index: 5;
}

.frames-loader svg {
  border-radius: 8px;
}

@media (max-width: 1300px) {
  .frames-loader {
    padding: 0 24px;
  }
}
